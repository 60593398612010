import React from 'react';
import SocialIcons from '@src/components/SocialButtons/SocialIcons';

const LINK_URLS = {
  facebook: 'https://www.facebook.com/puls24news',
  instagram: 'https://www.instagram.com/puls24news/',
  twitter: 'https://twitter.com/puls24news',
};

const FollowUs = () => (
  <div className="social-button-group">
    <SocialIcons baseUrls={LINK_URLS} />
  </div>
);

export default FollowUs;
